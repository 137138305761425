import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"

const ThankYou = () => {
  const banner = useSelector(state => state.thankyou.banner)
  const { language } = useSelector(state => state.global)

  useEffect(() => {
    const backToHome = setTimeout(() => {
      navigate("/")
    }, 2000)
    return () => clearTimeout(backToHome)
  }, [])

  return (
    <section>
      <div className="erroe_page thankyou">
        <div className="error_01">
          <img alt="thankyou-img" src={banner.img.sourceUrl} />
        </div>
        <div className="error_02">
          <h1 className="test m-0">{banner.title[language]}</h1>
          <p>{banner.text[language]}</p>
        </div>
      </div>
    </section>
  )
}

export default ThankYou
