/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import SEO from "../components/seo"
import { useDispatch } from "react-redux"
import { graphql, StaticQuery } from "gatsby"
import {
  THANKYOU_PAGE_QUERY_REQUEST,
  THANKYOU_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes"

import ThankYou from "../components/ThankYou/thankyou"

const ThankYouPage = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToxMjMx") {
          thankyou_contents {
            banner {
              buttonText {
                en
                sv
              }
              img {
                title
                sourceUrl
              }
              pretitle {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                sv
                en
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: THANKYOU_PAGE_QUERY_REQUEST })
        dispatch({ type: THANKYOU_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <>
            <SEO pageId="cGFnZToyMTcw" />
            <ThankYou />
          </>
        )
      }}
    />
  )
}
export default ThankYouPage
